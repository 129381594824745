// If you want to override variables do it here
@import "variables";

// Import CoreUI styles
@import "~@coreui/coreui/scss/coreui.scss";

// If you want to add something do it here
@import "custom";


.rundetails-container{
    table{
        th{
          color: #768192;
          background-color: #d8dbe0;
          border-color: #d8dbe0;
        }
        tr{
            td:first-child{
                font-weight: 700;
                width: 180px;
                color:green;
            }
            td:nth-child(2){
                font-weight: 700;
                width: 250px;
            }
        }
    }
    .comments{
        label{
            font-weight: 700;
        }
    }
  }