// Here you can add other styles
.row-align-center {
  align-items: center;
}

.card {
  box-shadow: 0 3px 6px #00000029, 0 3px 6px #0000003b;
  border: none;
}

